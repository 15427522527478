<template>
  <container>
    <page-header>
      <grid>
        <grid-column eight>
          <h1>Relationships</h1>
        </grid-column>
        <grid-column eight right-aligned middle-aligned>
          <btn @click.native="$refs.modal.show()"> + Create Relationship </btn>
        </grid-column>
      </grid>
    </page-header>
    <loading v-if="pageLoading || formLoading" :loading="true" />
    <template v-else>
      <list
        class="relationships"
        middle-aligned
        divided
        selection
        very-relaxed
        size="large"
      >
        <list-item
          v-for="relationship in relationships"
          :key="relationship.id"
          :to="{ name: 'relationship', params: { id: relationship.id } }"
        >
          <list-content>{{ relationship.name }}</list-content>
        </list-item>

        <placeholder
          v-if="relationships.length === 0"
          vertically-aligned
          centered
          light
        >
          {{ placeholderText }}
        </placeholder>
      </list>
      <pagination
        v-if="relationshipsPagination && relationshipsPagination.lastPage > 1"
        :num-of-pages="relationshipsPagination.lastPage"
      />
    </template>
    <modal ref="modal" size="tiny" @deny="reset">
      <modal-header> Create Relationship </modal-header>
      <modal-content>
        <g-form id="create-relationship-form" @submit="create">
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isFormValid = value)"
            />
            <form-field>
              <form-label>Name</form-label>
              <form-input
                name="Name"
                :rules="['required', 'max:255']"
                :initial-value="newRelationship.name"
                placeholder="Prefect"
                @update="(value) => (newRelationship.name = value)"
              />
            </form-field>
            <form-field-group equal-width>
              <form-field>
                <form-label>Role One</form-label>
                <form-input
                  name="Role One"
                  :rules="['max:255']"
                  :initial-value="newRelationship.superiorTitle"
                  placeholder="Prefect"
                  @update="(value) => (newRelationship.superiorTitle = value)"
                />
              </form-field>
              <i
                class="
                  fas
                  fa-long-arrow-alt-right
                  connection-icon
                  mobile
                  hidden
                "
              />
              <form-field>
                <form-label>Role Two</form-label>
                <form-input
                  name="Role Two"
                  :rules="['max:255']"
                  :initial-value="newRelationship.subordinateTitle"
                  placeholder="Student"
                  @update="
                    (value) => (newRelationship.subordinateTitle = value)
                  "
                />
              </form-field>
            </form-field-group>
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn
          variant="default"
          type="reset"
          form-id="create-relationship-form"
        >
          Cancel
        </form-btn>

        <form-btn
          variant="primary"
          type="submit"
          :invalid="!isFormValid"
          form-id="create-relationship-form"
        >
          Create
        </form-btn>
      </modal-actions>
    </modal>
  </container>
</template>

<script>
import { api } from '@/api'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import { toast } from '@/toasts'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import btn from '@/components/v2/btn.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import modalContent from '@/components/v2/modal/content.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import formFieldGroup from '@/components/v2/form/field_group.vue'
import formInput from '@/components/v2/form/input.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formValidation from '@/components/v2/form/validation.vue'
import grid from '@/components/v2/grid/grid.vue'
import formLabel from '@/components/v2/form/label.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import placeholder from '@/components/v2/placeholder.vue'
import pageHeader from '@/components/v2/page_header.vue'
import listContent from '@/components/v2/list/content.vue'

export default {
  components: {
    list,
    listItem,
    loading,
    container,
    pagination,
    btn,
    modal,
    modalActions,
    modalContent,
    modalHeader,
    gForm,
    formField,
    formInput,
    formFieldGroup,
    formBtn,
    formValidation,
    formLabel,
    grid,
    gridColumn,
    placeholder,
    pageHeader,
    listContent,
  },
  data() {
    return {
      relationships: [],
      relationshipsPagination: null,
      pageLoading: false,
      formLoading: false,
      newRelationship: {
        name: '',
        superiorTitle: '',
        subordinateTitle: '',
      },
      isFormValid: false,
    }
  },
  computed: {
    placeholderText() {
      return "There's no relationships yet, click + Create Relationship to make one"
    },
  },
  watch: {
    $route: {
      handler() {
        this.getRelationships()
      },
    },
  },
  mounted() {
    this.getRelationships()
  },
  methods: {
    async getRelationships() {
      try {
        this.pageLoading = true
        const response = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships`,
          {
            page: parseInt(this.$route.query.page || 1, 10),
            sort: 'name,id',
          }
        )
        this.relationships = response.data
        this.relationshipsPagination = response.cursor
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    reset() {
      this.newRelationship = {
        name: '',
        superiorTitle: '',
        subordinateTitle: '',
      }
    },
    async create(submission) {
      try {
        this.formLoading = true
        const result = await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships`,
          {
            name: submission.get('Name'),
            superiorTitle: submission.get('Role One'),
            subordinateTitle: submission.get('Role Two'),
            organizationId: this.$store.state.organizationId,
          }
        )
        this.$router.push({
          name: 'relationship',
          params: {
            id: result.data.id,
          },
        })
      } catch (error) {
        toast.error(error)
      } finally {
        this.formLoading = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';
@import '~@/assets/less/responsive.less';

.connection-icon {
  padding: 0 0.25em;
  font-size: @large-icon-font-size;
  color: @grey;
  transform: translateY(1.5em);
}

.relationships {
  padding-top: 1em;
}
</style>
