import { render, staticRenderFns } from "./relationships.vue?vue&type=template&id=5d45d810&scoped=true&"
import script from "./relationships.vue?vue&type=script&lang=js&"
export * from "./relationships.vue?vue&type=script&lang=js&"
import style0 from "./relationships.vue?vue&type=style&index=0&id=5d45d810&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d45d810",
  null
  
)

export default component.exports